/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/**
 * @license
 * MyFonts Webfont Build ID 3470465, 2017-10-23T10:25:07-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-65-bold/
 * Licensed pageviews: 500,000
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * Licensed pageviews: 250,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3470465
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2006 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced, 
 * 
 * © 2017 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'Frutiger45Light';
  src: url('/extras/fonts/34F481_1_0.eot');
  src: url('/extras/fonts/34F481_1_0.eot?#iefix') format('embedded-opentype'), url('/extras/fonts/34F481_1_0.woff2') format('woff2'), url('/extras/fonts/34F481_1_0.woff') format('woff'), url('/extras/fonts/34F481_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Frutiger65Bold';
  src: url('/extras/fonts/34F481_0_0.eot');
  src: url('/extras/fonts/34F481_0_0.eot?#iefix') format('embedded-opentype'), url('/extras/fonts/34F481_0_0.woff2') format('woff2'), url('/extras/fonts/34F481_0_0.woff') format('woff'), url('/extras/fonts/34F481_0_0.ttf') format('truetype');
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 15px;
  margin-bottom: 15px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 15px;
  margin-bottom: 15px;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #004896;
  color: #004896;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #46474F !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 7px 0;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #46474F;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #46474F;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #46474F;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #003c7d;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #c7c8cd;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #004896;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #004896;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1025px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid transparent;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid transparent;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid transparent;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid transparent;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid transparent;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'Frutiger65Bold', helvetica, sans-serif;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid transparent;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 72, 150, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 19px;
  font-size: 1.9rem;
  width: 100%;
  background-color: transparent;
  color: #46474F;
  font-family: 'Frutiger45Light', helvetica, sans-serif;
  line-height: 1.37;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
  width: 116px;
  margin-top: 20px;
}
.cb-layout4 #home {
  position: absolute;
  left: 6.66666667%;
  top: 25%;
  margin: 0;
  z-index: 2;
  width: 25%;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
#view.cb-layout4 #head {
  display: none;
}
.cb-mobile-access {
  display: none !important;
}
.cb-mobile-invisible {
  display: block !important;
}
#wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  position: relative;
  hyphens: manual;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  z-index: 50;
}
.section--header .desk {
  height: 0;
}
.cb-layout4 .section--header {
  display: none;
}
.section--navigation {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 49;
  min-height: 60px;
}
#edit .section--navigation {
  position: absolute;
}
.section--mood {
  display: none;
  margin-top: 80px;
}
.cb-layout4 .section--mood {
  display: block;
}
.section--footer {
  background: #46474F;
  color: #fff;
  font-size: 16px;
  line-height: 1.375;
  margin-top: 30px;
}
.section--footer a {
  color: #fff;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #AEAEAE;
}
.cb-layout1 .section--footer,
.cb-layout2 .section--footer {
  margin-top: 55px;
}
.navigation {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 116px;
}
.cb-layout4 .navigation {
  padding-left: 0;
}
.maincontent {
  float: left;
  width: 100%;
  margin-top: 190px;
}
.cb-layout4 .maincontent {
  margin-top: 15px;
}
.footarea {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.footpart {
  float: left;
  width: 100%;
}
.vcard {
  float: left;
  width: 100%;
}
.vcard .standort {
  display: block;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
}
.vcard .org,
.vcard .adr,
.vcard .street-address,
.vcard .postfach,
.vcard .tel {
  display: block;
}
.vcard .tel a {
  text-decoration: none;
}
.footpart .dock {
  float: left;
  display: block;
}
.footpart .dock .meta {
  margin-top: 4px;
  display: block;
  color: #AEAEAE;
}
.footpart .dock .meta:first-child {
  margin-top: 0;
}
.footpart .dock .meta:hover,
.footpart .dock .meta:focus {
  color: #fff;
}
.toplink {
  position: absolute;
  right: 0;
  top: 55px;
  z-index: 2;
  color: #AEAEAE;
  padding-left: 20px;
  background: url(/images/icon-arrow-up--gray-light.svg) no-repeat 0 50%;
  background-size: 14px 14px;
}
.toplink:hover,
.toplink:focus {
  cursor: pointer;
  color: #fff;
  background-image: url(/images/icon-arrow-up--white.svg);
}
#services {
  float: left;
  width: 100%;
  display: block;
  margin-top: 52px;
  margin-bottom: 56px;
}
#services .meta {
  float: left;
  margin-right: 30px;
  color: #AEAEAE;
}
#services .meta:last-child {
  margin-right: 0;
}
#services .meta:hover,
#services .meta:focus {
  color: #fff;
}
.mood {
  float: left;
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/images/mood--desktop-2017-11-27.jpg);
}
.mietobjekte {
  float: left;
  width: 100%;
}
.mietobjekte iframe {
  height: 3660px !important;
  max-height: 10000000px !important;
}
@media (max-width: 1024px) {
  .mietobjekte iframe {
    height: 3120px !important;
  }
}
@media (max-width: 767px) {
  .mietobjekte iframe {
    height: 2730px !important;
  }
}
h1 {
  font-weight: normal;
  font-size: 30px;
  line-height: 1.27;
  color: #004896;
}
.unit h2 {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #004896;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
}
div.fold h2 {
  color: #46474F;
  padding-top: 0;
  padding-bottom: 0;
}
.unit h2 > strong {
  font-family: 'Frutiger45Light', helvetica, sans-serif;
}
div.fold.fold-active h2 {
  border-bottom-color: #46474F;
}
.cb-layout4 .unit h2 {
  padding-bottom: 0;
  font-size: 30px;
  line-height: 1.27;
}
h3 {
  font-weight: normal;
  font-size: 30px;
  line-height: 1.27;
  color: #004896;
}
h4 {
  color: #004896;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  font-size: 22px;
  line-height: 1.18181818;
}
strong {
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
}
.loud,
.list {
  color: #004896;
}
.fold .list {
  color: #46474F;
}
.pale {
  font-size: 16px;
  line-height: 1.19;
  color: #004896;
}
#view .pale {
  margin-top: -15px;
}
a {
  color: #004896;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #46474F;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.list + div.list {
  margin-top: 0;
}
div.list .gist {
  list-style: none;
  margin-left: 0;
  position: relative;
  padding-left: 20px;
}
div.list .gist:before {
  content: '–';
  position: absolute;
  left: 0;
  top: 0;
}
div.link {
  background: none !important;
}
div.link a.open {
  margin-left: 0;
  text-decoration: none;
  color: #46474F;
  padding-left: 20px;
  background: url(/images/icon-arrow-right--gray.svg) no-repeat 0 6px;
  background-size: 14px 14px;
}
div.link a.open .edit {
  background: none !important;
}
div.link a.open:hover,
div.link a.open:focus {
  color: #004896;
  background-image: url(/images/icon-arrow-right--blue.svg);
}
div.line {
  padding: 0;
  margin-top: 15px;
}
#edit div.line {
  padding: 5px 0;
}
.cb-layout2 div.main {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout3 div.main {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 30px;
}
.cb-layout3 div.main div.unit {
  width: 100%;
  margin: -1px 0 0 0;
  border-top: 1px solid #004896;
  border-bottom: 1px solid #004896;
}
.cb-layout3 div.main div.unit div.body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-layout3 div.main div.unit div.head h2,
.cb-layout3 div.main div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout3 div.main div.unit div.part {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout3 div.main div.unit div.tall {
  width: 100%;
}
#view.cb-layout3 div.main div.unit div.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
}
#view.cb-layout3 div.main div.unit div.link a.open {
  display: block;
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  padding: 0;
  background: none;
}
.cb-layout4 div.main {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.cb-layout4 div.main div.unit {
  background-color: #F0F1F2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 15px;
  padding-top: 15px;
}
.cb-layout4 div.main div.unit.flat {
  background-color: #E7EFF8;
}
.cb-layout4 div.main div.unit div.head {
  float: left;
  width: 100%;
}
.cb-layout4 div.main div.unit div.pict.tall.tall {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#view.cb-layout4 div.main div.unit div.pict.tall.tall:last-child {
  margin-bottom: -15px;
}
#view.cb-layout4 div.main div.unit div.pict.tall.tall:first-child {
  margin-top: -15px;
}
.cb-layout4 div.main div.text + div.link {
  margin-top: 3px;
}
.cb-layout4 div.main div.list .gist {
  padding-left: 0;
}
.cb-layout4 div.main div.list .gist:before {
  display: none;
}
#view.cb-layout4 div.main div.unit div.list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;
}
#view.cb-layout4 div.main div.unit div.list .gist {
  display: block;
  height: 100%;
}
#view.cb-layout4 div.main div.unit div.list a.open {
  display: block;
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  padding: 0;
  background: none;
}
div.seam {
  margin-bottom: 0 !important;
  border: none !important;
  background: none !important;
}
div.seam div.head h2 {
  padding-bottom: 0 !important;
}
div.seam div.part:last-child {
  margin-bottom: 0 !important;
}
div.fold {
  margin-top: 30px;
  margin-bottom: 30px;
}
div.fold + div.fold {
  margin-top: -31px;
}
div.fold div.head h2 {
  position: relative;
}
div.fold .fold-toggle {
  display: block;
  z-index: 2;
  background-size: 24px 24px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-plus-gray.svg);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 40px;
  border-top: 1px solid #46474F;
  border-bottom: 1px solid transparent;
  color: #46474F;
  text-decoration: none;
}
div.fold .fold-toggle.fold-toggle--open {
  background-image: url(/images/icon-minus-gray.svg);
}
div.fold .fold-toggle:hover,
div.fold .fold-toggle:focus {
  cursor: pointer;
}
div.fold div.more {
  float: left;
  width: 100%;
}
div.fold div.more div.part:last-child {
  margin-bottom: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #46474F;
}
div.fold div.foot {
  display: none;
}
#root div.cb-slideshow div.head {
  position: static;
}
#root div.cb-slideshow div.head h2:empty {
  display: none;
}
#root div.cb-slideshow div.part.foto {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#root div.cb-slideshow div.part.foto a.foto {
  display: block;
  height: 100%;
  top: 0;
  left: 0;
}
#root div.cb-slideshow div.part.foto a.foto.is-active {
  z-index: 2;
}
#root div.cb-slideshow div.ctrl {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  z-index: 5;
  height: 0;
  margin-top: -20px;
}
#root div.cb-slideshow div.ctrl a {
  float: left;
  display: block;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#root div.cb-slideshow div.ctrl a.fade {
  opacity: 0.4;
  pointer-events: none;
}
#root div.cb-slideshow div.ctrl div.prev {
  float: left;
  margin-left: 35px;
}
#root div.cb-slideshow div.ctrl div.prev a {
  background-image: url(/images/icon-album-left-blue.svg);
}
#root div.cb-slideshow div.ctrl div.next {
  float: right;
  margin-right: 35px;
}
#root div.cb-slideshow div.ctrl div.next a {
  background-image: url(/images/icon-album-right-blue.svg);
}
div.form div.body {
  font-size: 16px;
  line-height: 1.2;
}
div.form input.text,
div.form textarea {
  border: 1px solid #F0F1F2;
  box-shadow: none;
  background: #F0F1F2;
  border-radius: 0;
  padding: 5px 10px;
  min-height: 50px;
  color: #004896;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  transition: border 0.218s;
}
div.form input.text:focus,
div.form textarea:focus {
  border-color: #004896;
}
div.form input.text.fail,
div.form textarea.fail {
  background-color: #F0F1F2 !important;
  border-color: #BE4932;
  color: #004896 !important;
}
div.form div.tick div.ctrl > div {
  margin-top: 6px;
}
div.form input.submit {
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  background: none;
  padding: 0 0 0 20px;
  min-height: 0;
  line-height: 1.37;
  font-size: 19px;
  color: #46474F;
  background: url(/images/icon-arrow-right--gray.svg) no-repeat 0 6px;
  background-size: 14px 14px;
}
div.form input.submit:hover,
div.form input.submit:focus {
  color: #004896;
  background-image: url(/images/icon-arrow-right--blue.svg);
}
div.form div.foot {
  padding-bottom: 15px;
}
div.form div.fail {
  background-color: #F0F1F2 !important;
  border-color: #BE4932;
  color: #BE4932 !important;
}
div.form div.cb-form-sent {
  margin-top: 15px;
  margin-bottom: 15px;
  background: #004896;
  border: none;
  display: block;
}
div.form div.cb-form-sent a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
}
#root div.cb-filter.cats {
  margin-bottom: 30px !important;
}
div.cb-filter.cats ul {
  float: left;
  width: 100%;
  display: block;
}
div.cb-filter.cats ul li {
  float: left;
  display: block;
  padding: 0;
  background: none;
  color: #46474F;
  margin: 0 8px;
}
div.cb-filter.cats ul li:first-child {
  margin-left: 0;
}
div.cb-filter.cats ul li:last-child {
  margin-right: 0;
}
div.cb-filter.cats ul li a,
div.cb-filter.cats ul li span {
  float: left;
  padding: 0;
  color: #AEAEAE;
  font-size: 16px;
  line-height: 1.25;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  text-decoration: none;
}
div.cb-filter.cats ul li a:hover,
div.cb-filter.cats ul li span:hover,
div.cb-filter.cats ul li a:focus,
div.cb-filter.cats ul li span:focus {
  cursor: pointer;
  color: #004896;
}
div.cb-filter.cats ul li a.cb-count,
div.cb-filter.cats ul li span.cb-count {
  display: none;
}
div.cb-filter.cats ul li.same a,
div.cb-filter.cats ul li.same span {
  color: #004896;
}
div.grid {
  color: #004896;
}
.grow {
  text-transform: none !important;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.37em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #46474F;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Frutiger45Light', helvetica, sans-serif;
  font-size: 19px;
  font-size: 1.9rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.37em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 19px;
  font-size: 1.9rem;
  color: #444;
  font-weight: normal;
  font-family: 'Frutiger45Light', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #91929d;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  display: inline-block;
  background-color: #004896;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #004fa5;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #003063;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -5px 0.3em 0 0;
  min-height: 1.37em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-top.less 2014-11-04 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  right: -100%;
  top: 0;
  width: 100%;
  padding-left: 6%;
  padding-right: 6%;
  z-index: 1999;
  height: 100%;
  background: #fff;
  -o-transition: all 0.28s ease-in;
  -moz-transition: all 0.28s ease-in;
  -webkit-transition: all 0.28s ease-in;
  transition: all 0.28s ease-in;
  box-sizing: border-box;
}
.togglenavigation {
  position: fixed;
  top: 20px;
  right: 6%;
  display: block;
  width: 30px;
  height: 20px;
  z-index: 2000;
  cursor: pointer;
  color: #004896 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #00346d !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 2px;
  width: 30px;
  margin-top: -1px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -10px;
}
.togglenavigation > .tline-4 {
  margin-top: 8px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  right: 0;
}
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
  border-top: 1px solid #46474F;
}
#mobile-navi .navi .item.exit {
  border-bottom: 1px solid #46474F;
}
#mobile-navi .navi .item.item-empty .menu {
  padding-right: 0;
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #004896;
  padding: 15px 0;
  padding-right: 24px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  text-decoration: none;
}
#mobile-navi .navi .item .menu.here {
  color: #46474F;
}
#mobile-navi .navi .item.c2 .menu {
  color: #46474F;
}
#mobile-navi .navi .item.c2 .menu.here {
  color: #004896;
}
#mobile-navi .navi.sub1 {
  margin-top: 110px;
  opacity: 0;
  transition: all 0.5s, opacity 0.3s;
}
body.cb-toggle-target-active #mobile-navi .navi.sub1 {
  opacity: 1;
}
#mobile-navi .navi.sub2 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub2 .item {
  border: none;
  margin: 11px 0;
}
#mobile-navi .navi.sub2 .item.init {
  margin-top: 8px;
}
#mobile-navi .navi.sub2 .item .menu {
  padding: 0;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 24px;
  height: 50px;
  background: url(/images/icon-plus-gray.svg) no-repeat 50% 50%;
  background-size: 24px 24px;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  background-image: url(/images/icon-minus-gray.svg);
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  width: 574px;
  max-width: 88%;
}
.desk.large,
.cb-layout4 .desk {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .cb-layout4 #home {
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }
  .section--mood {
    margin-top: 60px;
  }
  .mood {
    background-image: url(/images/mood--mobile-2017-11-27.jpg);
    padding: 0;
    height: 78vh;
  }
}
@media only screen and (max-width: 767px) {
  .footarea {
    margin-top: 10px;
  }
  .footpart {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .footpart.footpart--half {
    width: 50%;
  }
  .footpart .dock {
    width: 50%;
  }
  #services {
    margin-top: 26px;
    margin-bottom: 20px;
  }
  #services .meta {
    margin: 4px 0 0;
    display: block;
    float: none;
  }
  .toplink {
    position: relative;
    top: 0;
    float: left;
    margin: 20px 0 40px;
  }
  .vcard--Sumiswald,
  .vcard--Grosshoechstetten {
    margin-top: -20px;
  }
}
@media only screen and (max-width: 767px) {
  div.cb-filter.cats ul li {
    margin: 6px 0;
    width: 100%;
  }
  div.cb-filter.cats ul li:first-child {
    margin-top: 0;
  }
  div.cb-filter.cats ul li:last-child {
    margin-bottom: 0;
  }
  .cb-layout3 div.main div.unit {
    padding-bottom: 10px;
  }
  .cb-layout3 div.main div.unit div.part {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cb-layout3 div.main div.unit div.part + div.part {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  #root div.cb-slideshow div.ctrl div.prev {
    margin-left: 0;
  }
  #root div.cb-slideshow div.ctrl div.next {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1023px) {
  h1 {
    font-size: 24px;
    line-height: 1.25;
  }
  .cb-layout4 .unit h2 {
    font-size: 24px;
    line-height: 1.25;
  }
  h3 {
    font-size: 24px;
    line-height: 1.25;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .cb-layout4 div.main div.unit div.head h2,
  .cb-layout4 div.main div.unit div.part {
    margin-left: 8%;
    margin-right: 8%;
  }
  .cb-layout4 div.main div.unit div.part {
    width: 84%;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/* scan.less 2013-1-16 */
.scan .cb-hybrid {
  color: #46474F;
  text-decoration: none;
}
.scan .cb-hybrid:hover,
.scan .cb-hybrid:focus {
  color: #161619;
}
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(70, 71, 79, 0.3);
}
.unit.cb-batch .same {
  background-color: #003063;
  color: #46474F;
}
/******* module-album.less 2013-1-16 *******/
.body-mobile img,
noscript img {
  /* resize - noscript mode must still work */
  width: 100%;
}
img.same {
  background: none !important;
}
div.foto div.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.cb-mobile .cb-slideshow > .head > .ctrl {
  display: none;
}
/*.cb-slideshow > .body-mobile img.zoom {
  top: 100% !important;
  left: 100% !important;
  display: block !important;
  margin: -20px 0 0 -20px !important;
  width: 17px !important;
  height: 17px;
  opacity: .7;
}*/
div.foto,
.crossslideContainer {
  position: relative;
}
.crossslide,
.cb-slideshow-images img {
  width: 100%;
}
.cb-strips .foto > .cb-landscape,
.cb-matrix .body-mobile .foto > img,
.cb-contentflow .foto > img,
.cb-slideshow .foto > .cb-landscape,
.body-non-mobile .foto > img {
  margin: 0 auto;
  width: 100%;
}
body:not(.cb-mobile) .cb-slideshow .foto > .cb-portrait,
body:not(.cb-mobile) .cb-strips .foto > .cb-portrait {
  height: 100%;
  width: auto;
}
.body-mobile a.foto,
.body-mobile span.foto {
  width: 100%;
}
noscript a.foto {
  position: relative !important;
}
.body-mobile .cb-matrix .foto,
.body-mobile .cb-contentflow .foto {
  position: relative;
}
.part.desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.same.norm,
.same.foto {
  background: none;
}
.body-non-mobile a.foto,
.body-non-mobile span.foto {
  position: absolute;
  left: 0;
  display: block;
  padding: 0;
  width: 100%;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 18px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  display: block;
  height: 18px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  background-image: url('/icons/prev_next.png');
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl > .prev,
.cb-slideshow .ctrl > .next {
  float: left;
}
.cb-album .prev {
  background-position: 0px -54px;
}
.cb-strips td.prev,
.cb-strips td.next {
  background: none;
  vertical-align: middle;
}
.cb-album a.prev,
.cb-album .prev > .cb-hybrid {
  background-position: 0px 0px;
}
.cb-album a.prev:hover,
.cb-album .prev > .cb-hybrid:hover {
  background-position: 0px -18px;
}
.cb-album a.prev:active,
.cb-album .prev > .cb-hybrid:active {
  background-position: 0px -36px;
}
.cb-album .prev > .cb-hybrid.fade {
  background-position: 0px -54px;
}
.cb-album .next {
  background-position: -17px -54px;
}
.cb-album a.next,
.cb-album .next > .cb-hybrid {
  background-position: -17px 0px;
}
.cb-album a.next:hover,
.cb-album .next > .cb-hybrid:hover {
  background-position: -17px -18px;
}
.cb-album a.next:active,
.cb-album .next > .cb-hybrid:active {
  background-position: -17px -36px;
}
.cb-album .next > .cb-hybrid.fade {
  background-position: -17px -54px;
}
.cb-strips table {
  border-collapse: collapse;
}
td > .body {
  display: inline-block;
  vertical-align: middle;
}
.cb-strips-images {
  height: 100%;
  width: 100%;
}
.cb-strips-images > .foto {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cb-strips-images > .foto > .cb-landscape {
  width: 100%;
  height: auto;
}
.cb-strips-images > .foto > .cb-portrait {
  width: auto;
  height: 100%;
}
.cb-strips-container > .body > .foto {
  height: 100% !important;
  overflow: hidden;
}
.cb-strips-thumbs a.foto {
  width: 100%;
}
.cb-strips-container > .body a.foto,
.cb-strips-thumbs .part > .foto > .foto {
  position: static;
  width: 100% !important;
  height: 100% !important;
}
.cb-strips-container .cb-landscape,
.cb-strips-container .cb-portrait {
  max-width: 100% !important;
  max-height: 100% !important;
}
.cb-strips-4 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 23% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 14.66666667% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 10.5% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 8% !important;
}
.cb-matrix .body-non-mobile > .foto {
  margin: 3% !important;
}
.cb-matrix .body-non-mobile a.foto {
  position: static;
  height: 100% !important;
}
.cb-matrix-columns-1 .body-non-mobile > .foto {
  width: 94% !important;
}
.cb-matrix-columns-3 .body-non-mobile > .foto,
.cb-matrix-columns-5 .body-non-mobile > .foto,
.cb-matrix-columns-6 .body-non-mobile > .foto,
.cb-matrix-columns-7 .body-non-mobile > .foto,
.cb-matrix-columns-8 .body-non-mobile > .foto {
  width: 44% !important;
}
@media only screen and (min-width: 240px) {
  .cb-matrix-columns-2 .body-non-mobile > .foto,
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 44% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 10.66666667% !important;
  }
}
@media only screen and (min-width: 768px) {
  .cb-matrix-columns-3 .body-non-mobile > .foto {
    width: 27.33333333% !important;
  }
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 19% !important;
  }
  .cb-matrix-columns-5 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 14% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto {
    width: 10.66666667% !important;
  }
}
@media only screen and (min-width: 1025px) {
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 8.28571429% !important;
  }
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 6.5% !important;
  }
}
.cb-contentflow .body-non-mobile .part {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.foto > .head {
  position: relative;
}
.cb-manual .desc,
.body-non-mobile > .desc {
  position: relative;
}
#view .cb-manual .desc .norm,
#view .body-non-mobile > .desc .norm {
  position: absolute;
  top: 0;
  left: 0;
}
.foto > .head > .ctrl {
  position: absolute;
}
.cb-album .ctrl {
  float: right;
}
#root div.foto div.body div.desc {
  float: left;
}
#root div.foto div.body div.desc div.part {
  float: none;
}
/******* module-filter.less 2013-1-16 *******/
.cb-count {
  padding: 0 !important;
}
.scan th {
  text-align: center;
  white-space: nowrap;
}
.tabs td,
.time td,
.beta td {
  text-align: center;
}
.mese table {
  table-layout: fixed;
}
.mese td,
.mese tbody th {
  text-align: right;
}
.mese td > .cb-hybrid {
  display: block;
}
.cats span {
  padding: 0;
}
.scan > p {
  font-size: 0;
  text-align: center;
}
.scan > p > span {
  padding: 0;
}
.scan > p > .same,
.scan > p > span > .cb-hybrid {
  padding: 0 0.3em;
}
p > span > .cb-hybrid {
  display: inline-block;
}
p > .same,
p > span > .same {
  display: inline-block;
  background: none !important;
}
/******* module-filter-small.less 2013-1-16 *******/
.cb-mobile-filter {
  font-size: 18px;
  font-size: 1.8rem;
  z-index: 20;
  display: inline-block;
  width: 100%;
  background: #004896;
  color: #555;
}
.cb-mobile-filter-link {
  width: inherit;
}
.cb-icon-filter:before {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  font-family: 'cbfont';
  line-height: 1em;
  speak: none;
  padding: 0 0.2em 0 0.6em;
  width: 1.2em;
  content: '\f0c9';
}
.cb-mobile-filter-items {
  padding-bottom: 1em;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
.cb-mobile-filter-items .cb-hybrid {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.4);
}
.cb-mobile-filter-items .cb-hybrid:hover,
.cb-mobile-filter-items .cb-hybrid:focus,
.cb-mobile-filter-items .cb-hybrid:active {
  background: #003c7d;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-filter-items .mese td > span,
.cb-mobile-filter-items li > span {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.cb-mobile-filter-items > .cb-filter {
  margin: 0 !important;
  width: 100% !important;
}
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding-right: 2%;
  padding-left: 2%;
}
.cb-mobile-filter-items li {
  display: inline-block;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid {
  position: relative;
  overflow: hidden;
  padding-right: 4%;
  padding-left: 4%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 4em;
  height: 100%;
  /*background-color: #1e1e1e;*/
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(30, 30, 30, 0)), to(#1e1e1e));
  background-image: -webkit-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -moz-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -o-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -ms-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  content: '';
}
.mese tbody th,
.mese td > .cb-hybrid,
.mese td > span {
  padding-right: 0.4em;
  padding-left: 0.4em;
}
.cb-mobile-filter-items > .beta {
  text-align: center;
}
.cb-mobile-filter-items > .beta > span {
  float: left;
  padding: 0;
  width: 20%;
  line-height: 3em;
}
.cb-mobile-filter-items > .beta > span > .cb-hybrid {
  padding: 0;
}
.cb-mobile-filter-items > .scan > p > span > .cb-hybrid {
  display: inline-block;
  overflow: hidden;
  max-width: 9em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.font9 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font12 {
  font-size: 16px;
  font-size: 1.6rem;
}
.font15 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font18 {
  font-size: 25px;
  font-size: 2.5rem;
}
.font21 {
  font-size: 29px;
  font-size: 2.9rem;
}
.font24 {
  font-size: 33px;
  font-size: 3.3rem;
}
.font27 {
  font-size: 37px;
  font-size: 3.7rem;
}
.font30 {
  font-size: 42px;
  font-size: 4.2rem;
}
.font32 {
  font-size: 44px;
  font-size: 4.4rem;
}
.cb-mobile-filter-items .cb-count,
.cb-mobile-filter-items .days td > span,
.cb-mobile-filter-items .week td > span,
.cb-mobile-filter-items .year td > span {
  display: none;
}
.cb-mobile-filter-items .same > span:first-child {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.keys input {
  display: table;
  margin: 0.3em auto;
  width: 86%;
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.keys input + .cb-hybrid {
  display: none;
}
.scan select {
  margin: 0.4em 2%;
  width: 96%;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #003c7d;
  color: #fff;
  text-decoration: none;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
#cb-cookie-warning {
  position: fixed;
  bottom: 36px;
  z-index: 10000;
  width: 500px;
  max-width: 100%;
  background-color: #46474F;
  color: #fff;
  font-family: 'Frutiger45Light', helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  box-sizing: border-box;
  padding: 30px;
  left: 0;
  transform: translateX(-100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  left: 0 !important;
  transform: translateX(-100%) !important;
  transition: all 0.3s !important;
}
.show-content #cb-cookie-warning {
  left: 36px;
  transform: translateX(0);
  transition: all 0.6s 0.2s ease;
}
@media (max-width: 767px) {
  .show-content #cb-cookie-warning {
    left: 0;
  }
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    bottom: 0;
    padding: 24px;
    border-radius: 0;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: bold;
}
.cb-cookie-warning--text a {
  margin-left: 0;
  text-decoration: none;
  color: #fff;
  padding-left: 20px;
  background: url(/images/icon-arrow-right--white.svg) no-repeat 0 6px;
  background-size: 14px 14px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
#cb-cookie-warning__button--accept {
  float: left;
  order: 1;
  font-family: 'Frutiger45Light', helvetica, sans-serif;
  color: #fff;
  text-underline-position: under;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  cursor: pointer;
  transition: all 0.4s ease;
}
#cb-cookie-warning__button--accept:hover {
  opacity: 0.8;
}
#cb-cookie-warning__button--decline {
  float: left;
  order: 2;
  font-family: 'Frutiger45Light', helvetica, sans-serif;
  color: #fff;
  text-underline-position: under;
  font-size: 18px;
  line-height: 1.33333333;
  cursor: pointer;
  transition: all 0.4s ease;
}
#cb-cookie-warning__button--decline:hover {
  opacity: 0.8;
}
/*# sourceMappingURL=./screen-small.css.map */